$('a[href*="#"]:not([href="#"])').on('click', function (e) {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    let $target = $(this.hash);
    $target = $target.length ? $target : $('[name=' + this.hash.slice(1) +']');
    if ($target.length) {
      $('html, body').animate({
        scrollTop: $target.offset().top
      }, 500);
      e.preventDefault();
      return false;
    }
  }
});

$('.js-scroll').each(function () {
  let $scroll  = $(this),
      $links   = $scroll.find('.js-scroll-link'),
      $items   = $scroll.find('.js-scroll-item'),
      $window  = $(window);
      
  $window.on('scroll', function () {
    let windowTop = $window.scrollTop();

    let $item = $items.filter(function(index, item) {
      let $item   = $(this),
          itemTop = $item.offset().top;
      return windowTop >= itemTop && windowTop < itemTop + $item.outerHeight();
    });
    
    $links.removeClass('is-active');
    let index = $items.index($item);
    if (index >= 0) {
      $links.eq(index).addClass('is-active');
    }
  }).trigger('scroll');
});
