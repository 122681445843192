$('.js-categories').each(function() {
  let $categories = $(this);
  
  $categories.on('click', function (e) {
    if ($categories.css('overflow') === 'hidden' && !$categories.hasClass('is-active')) {
      $categories.addClass('is-active');
      e.preventDefault();
    }
  });
});
