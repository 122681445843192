$('.js-accordion').each(function() {
  let $accordion = $(this),
      $titles  = $accordion.find('.js-accordion-title').attr('aria-expanded', false),
      $contents  = $accordion.find('.js-accordion-content').attr('aria-hidden', true);

  $titles.on('click', function() {
    let $title = $(this),
        index = $titles.index($title),
        state = $title.attr('aria-expanded') === 'false' ? true : false;
        
    $titles.attr('aria-expanded', false).eq(index).attr('aria-expanded', state);
    $contents.attr('aria-hidden', true).eq(index).attr('aria-hidden', !state);
  });
});
