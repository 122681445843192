$('.js-slideshow').each(function () {
  let $slideshow = $(this),
      $slides    = $slideshow.find('.js-slideshow-slides');
  
  if ($slides.children().length > 1) {
    let $progress  = $('<div class="slick-progress"/>').appendTo($slideshow),
        $nav       = $('<div class="slick-nav"/>').appendTo($slideshow);
    
    $slides.slick({
      // autoplay: true,
      // autoplaySpeed: 5000,
      appendArrows: $nav,
      draggable: false,
      fade: true,
      infinite: true,
      mobileFirst: true,
      speed: 300,
      touchMove: false
    });
    
    let duration = 5000,
        isPaused = false,
        time = 0;
    
    $slideshow.on({
      mouseenter() {
        isPaused = true;
      },
      mouseleave() {
        isPaused = false;
      }
    });
    
    $slides.on('beforeChange', function () {
      time = 0;
      $progress.css('width', '0%');
    });
    
    setInterval(() => {
      if (!isPaused) {
        time += 10;
        $progress.css('width', (time * 100 / duration) + '%');
        
        if (time == duration) {
          $slides.slick('slickNext');
        }
      }
    }, 10);
  }
});
